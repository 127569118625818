import { ShopifyItemCatalogCard } from './shopifyItemCatalogCard';

interface ItemCatalogCardValue {
  products: {
    productId?: string;
    variantId: string;
    quantity: number;
    price?: number;
  }[];
  currency: string | undefined;
}

export const ShopifyItemCatalogCardTable = (props: ItemCatalogCardValue) => {
  return (
    <table id="item_catalog_table">
      <tbody>
        <tr className="item-catalog-table-header-row">
          <th>Item</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
        {(props.products ?? []).map((product) => (
          <ShopifyItemCatalogCard
            key={product.variantId}
            quantity={product.quantity}
            productId={product.productId}
            variantId={product.variantId}
            price={product.price}
            currency={props.currency}
          />
        ))}
      </tbody>
    </table>
  );
};
