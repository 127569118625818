import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState, AppThunk } from 'app/store';
import { AuthProvider as Provider, usersCollection } from 'app/firebase/collections/users';
import { UserModel } from 'app/firebase/collections/users';
import { User } from 'firebase/auth';
import { localStorageAdminAccount, localStorageApiKey } from 'app/localstorage';
import { graphQLClient } from 'app/graphql/client';
import { trackHeapIdentifyEvent, trackSegmentIdentifyEvent } from 'app/utils';
import { StepName } from 'components/AccordionItem';

import { getUser, updateAdminUser } from 'app/graphql';
import { AuthProvider } from 'app/graphql/generated/admin/graphql';

/**
 * Reducers
 */

type DialogPayload = { developersDialog: boolean };

type UserState = {
  loadingInfo: boolean;
  loadingWebhook: boolean;
  loadingJwtValidationKey: boolean;
  loadingMargin: boolean;
  uid: string | null;
  info: UserModel | null;
  documentId: string | null;
  dialogs: DialogPayload;
};

interface SetPayload {
  userInfo: UserModel;
  documentId: string | undefined;
}

const initialState: UserState = {
  loadingInfo: false,
  loadingWebhook: false,
  loadingJwtValidationKey: false,
  loadingMargin: false,
  uid: null,
  info: null,
  documentId: null,
  dialogs: { developersDialog: false },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clean: () => {
      return { ...initialState };
    },
    loadingInfo: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loadingInfo: action.payload,
        loadingJwtValidationKey: action.payload,
        loadingWebhook: action.payload,
        loadingMargin: action.payload,
      };
    },
    loadingJwtValidationKey: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loadingJwtValidationKey: action.payload,
      };
    },
    loadingWebhook: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loadingWebhook: action.payload,
      };
    },
    loadingMargin: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loadingMargin: action.payload,
      };
    },
    login: (state, action: PayloadAction<{ uid: string }>) => {
      return {
        ...state,
        loadingInfo: true,
        loadingWebhook: true,
        loadingJwtValidationKey: true,
        loadingMargin: true,
        uid: action.payload.uid,
      };
    },
    set: (state, action: PayloadAction<SetPayload>) => {
      return {
        ...state,
        loadingInfo: false,
        loadingWebhook: false,
        loadingJwtValidationKey: false,
        loadingMargin: false,
        info: action.payload.userInfo,
        documentId: action.payload.documentId || initialState.documentId,
      };
    },
    update: (state, action) => {
      return {
        ...state,
        loadingInfo: false,
        loadingWebhook: false,
        loadingJwtValidationKey: false,
        loadingMargin: false,
        info: {
          ...state.info,
          ...action.payload,
        },
      };
    },
  },
});

/**
 * Actions
 */
const { login, clean, set, update, loadingWebhook, loadingJwtValidationKey, loadingMargin } =
  userSlice.actions;

export const cleanUserInfo = (): AppThunk => async (dispatch) => {
  // Reset GQL-client
  graphQLClient.reset();
  // Remove from LocalStorage
  localStorageApiKey.remove();
  // Remove admin account from LocalStorage
  localStorageAdminAccount.remove();
  // Remove data in Store
  dispatch(clean());
};

const getAuthProvider = (provider: AuthProvider) => {
  switch (provider) {
    case AuthProvider.Google:
      return Provider.Google;
    case AuthProvider.Local:
      return Provider.Local;
    case AuthProvider.Firebase:
      return Provider.Firebase;
    default:
      throw new Error('Unknown auth provider');
  }
};

export const fetchUserInfo =
  (uid: string, user: User, website?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const noUserInfoInStore = !state.user.info || !state.user.loadingInfo;

    const claims = (await user.getIdTokenResult()).claims;

    if (noUserInfoInStore) {
      dispatch(login({ uid }));
      const token = await user.getIdToken();
      graphQLClient.set(undefined, token);

      const {
        data: {
          user: { data: userData },
        },
      } = await getUser(undefined, token);

      let userInfoModel = userData?.user;
      let billingPeriodSettings = userData?.billingPeriodSettings;
      let stripeConfig = userData?.stripeConfig;
      let amazonBusinessAccount = userData?.amazonBusinessAccount;
      let billingNotificationSettings = userData?.notificationSettings?.billingNotifications;

      const displayName = userData?.user.displayName ?? '';
      const firstName = userData?.user.displayName?.split(' ')?.at(0) ?? '';
      const lastName = userData?.user.displayName?.split(' ')?.at(1) ?? '';

      if (!user.email) {
        throw Error('User email is missing');
      }

      if (website && !userInfoModel?.website) {
        await updateAdminUser({
          updates: {
            website: website,
          },
          adminToken: token,
        });
      }

      let firstLogin = false;

      if (!userInfoModel) {
        firstLogin = true;
        const updateUser = await updateAdminUser({
          updates: {
            firstName,
            lastName,
            displayName: displayName,
            email: user.email,
            website: website ?? '',
          },

          adminToken: token,
        });
        userInfoModel = updateUser.data?.updateUser?.data?.user;
        billingPeriodSettings = updateUser.data?.updateUser?.data?.billingPeriodSettings;
        stripeConfig = updateUser.data?.updateUser?.data?.stripeConfig;
        amazonBusinessAccount = updateUser.data?.updateUser?.data?.amazonBusinessAccount;
        billingNotificationSettings =
          updateUser.data?.updateUser?.data?.notificationSettings?.billingNotifications;

        if (!userInfoModel) {
          throw Error('Could not create user');
        }
      }

      const isReadOnly = claims.readOnly ?? false;
      const userInfo: UserModel = {
        ...userInfoModel,
        uid: userInfoModel.id,
        authProvider: getAuthProvider(userInfoModel.authProvider),
        webhookURL: userInfoModel.webhookURL ?? '',
        webhookURLVerified: userInfoModel.webhookURLVerified ?? false,
        marginAmount: userInfoModel.marginAmount ?? 0,
        marginIsPercent: userInfoModel.marginIsPercent ?? false,
        jwtValidationKey: userInfoModel.jwtValidationKey ?? '',
        hmacSecretKey: userInfoModel.hmacSecretKey ?? '',
        stripe: stripeConfig,
        billingPeriodSettings,
        billingNotificationSettings,
        amazonBusinessAccount: amazonBusinessAccount,
        onBoardingPresentationComplete: !firstLogin,
        adminToken: token,
        isReadOnly,
      };

      // Save to Redux Store
      dispatch(set({ userInfo, documentId: userInfo.uid }));
      // Save to LocalStorage
      localStorageApiKey.replace(userInfo.apiKey);

      // Reset GraphQL client
      graphQLClient.set(userInfo.apiKey, token);

      // Fire analytics event
      trackHeapIdentifyEvent(userInfo);
      trackSegmentIdentifyEvent(userInfo);
    }
  };

export const updateWebhookURL =
  (webhookURL: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const isLogin = state.user.info && !state.user.loadingInfo;
    const sameVal = state.user.info?.webhookURL === webhookURL;

    if (isLogin && !sameVal) {
      dispatch(loadingWebhook(true));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await usersCollection.updateWebhookURL(state.user.info!.uid, webhookURL);
      dispatch(update({ webhookURL }));
    }
  };

export const updateJwtValidationKey =
  (jwtValidationKey: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const isLogin = state.user?.info && !state.user.loadingInfo;
    const sameVal = state.user.info?.jwtValidationKey === jwtValidationKey;
    const adminToken = state.user.info?.adminToken;

    if (!adminToken) {
      throw new Error('adminToken is undefined');
    }

    if (isLogin && !sameVal) {
      dispatch(loadingJwtValidationKey(true));
      await updateAdminUser({
        updates: { account: { jwtValidationKey } },
        adminToken,
      });
      dispatch(update({ jwtValidationKey }));
    }
  };

export const updateMarginAmount =
  (marginAmount = 0, marginIsPercent: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const isLogin = state.user.info && !state.user.loadingInfo;
    const sameVal =
      state.user.info &&
      state.user.info.marginAmount === marginAmount &&
      state.user.info.marginIsPercent === marginIsPercent;

    const adminToken = state.user.info?.adminToken;

    if (!adminToken) {
      throw new Error('adminToken is undefined');
    }

    if (isLogin && !sameVal) {
      dispatch(loadingMargin(true));
      await updateAdminUser({
        updates: { account: { margin: { amount: marginAmount, isPercent: marginIsPercent } } },
        adminToken,
      });
      dispatch(update({ marginAmount, marginIsPercent }));
    }
  };

export const updateOnboardingTasksCompleteStatus =
  (stepName: StepName, newStatus: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const isLogin = state.user.info && !state.user.loadingInfo;
    let prevState = state.user.info?.onboardingTasksStatus;

    if (prevState === undefined) {
      prevState = {
        [StepName.ExploreDemo]: false,
        [StepName.Tutorial]: false,
        [StepName.ResourceDocs]: false,
        [StepName.BookACall]: false,
        [StepName.Community]: false,
      };
    }

    if (isLogin && prevState) {
      const updatedStatus = { ...prevState, [stepName]: newStatus };
      dispatch(update({ onboardingTasksStatus: updatedStatus }));
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await usersCollection.updateOnboardingTasksStatus(state.user.info!.uid, updatedStatus);
      } catch (e) {
        dispatch(update({ onboardingTasksStatus: prevState }));
      }
    }
  };

export const onBoardingPresentationCompleted = (): AppThunk => async (dispatch) => {
  dispatch(update({ onBoardingPresentationComplete: true }));
};

/**
 * Selectors
 */
export const selectUserInfo = (state: RootState) => state.user.info;
export const selectDialog = (state: RootState) => state.user.dialogs;
export const selectDashboardButtonsState =
  (step: StepName) =>
  (state: RootState): boolean =>
    Boolean(state.user.info?.onboardingTasksStatus?.[step]);
export const selectOnBoardingPresentationStatus = (state: RootState) =>
  state.user.info?.onBoardingPresentationComplete;
export const selectUserInfoLoading = (state: RootState) => state.user.loadingInfo;
export const selectUserWebhookLoading = (state: RootState) =>
  state.user.loadingInfo || state.user.loadingWebhook;
export const selectUserJwtValidationKeyLoading = (state: RootState) =>
  state.user.loadingInfo || state.user.loadingJwtValidationKey;
export const selectUserMarginLoading = (state: RootState) =>
  state.user.loadingInfo || state.user.loadingMargin;

export default userSlice.reducer;
