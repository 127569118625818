const DEFAULT_CURRENCY = 'USD';

export function formatPrice<TCurrency extends string>(
  decimalPriceValue: number,
  currencyCode: TCurrency,
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(decimalPriceValue);
}

export const getFormattedCurrency = (currency: string | undefined, value: number) => {
  // TODO: update locale to use config value/enum
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency === '' || currency === undefined ? DEFAULT_CURRENCY : currency,
  }).format(value);
};

export const getFormattedAddress = (address1: string, address2: string) => {
  const address = [];
  if (address1 && address1 !== '') {
    address.push(address1);
  }

  if (address2 && address2 !== '') {
    address.push(address2);
  }

  return address.join(', ');
};
