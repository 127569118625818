/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query ShopifyAppInstallationLink($storeCanonicalDomain: String!) {\n    shopifyApp {\n      installationLink(storeCanonicalDomain: $storeCanonicalDomain) {\n        canonicalDomain\n        url\n      }\n    }\n  }\n':
    types.ShopifyAppInstallationLinkDocument,
  '\n  mutation RequestProductByURL($input: RequestProductByURLInput!) {\n    requestProductByURL(input: $input) {\n      productID\n    }\n  }\n':
    types.RequestProductByUrlDocument,
  '\n  mutation RequestStoreByURL($input: RequestStoreByURLInput!) {\n    requestStoreByURL(input: $input) {\n      requestID\n    }\n  }\n':
    types.RequestStoreByUrlDocument,
  '\n  query RequestShopifyProductByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      marketplace\n      title\n      description\n      vendor\n      url\n      images {\n        url\n      }\n      variants {\n        title\n        image {\n          url\n          ... on ShopifyImage {\n            position\n            width\n            height\n          }\n        }\n        ... on ShopifyVariant {\n          id\n        }\n      }\n      price {\n        value\n        currency\n        displayValue\n      }\n      ... on ShopifyProduct {\n        id\n        marketplace\n        storeCanonicalURL\n        title\n        description\n        vendor\n        url\n        price {\n          value\n          currency\n          displayValue\n        }\n      }\n    }\n  }\n':
    types.RequestShopifyProductByIdDocument,
  '\n  query RequestMarketplaceProductVendorByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      vendor\n    }\n  }\n':
    types.RequestMarketplaceProductVendorByIdDocument,
  '\n  query RequestAmazonProductByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      marketplace\n      title\n      description\n      vendor\n      url\n      images {\n        url\n      }\n      variants {\n        title\n        image {\n          url\n        }\n      }\n      price {\n        value\n        currency\n        displayValue\n      }\n      ... on AmazonProduct {\n        id\n        marketplace\n        title\n        description\n        vendor\n        url\n        price {\n          value\n          currency\n          displayValue\n        }\n      }\n    }\n  }\n':
    types.RequestAmazonProductByIdDocument,
  '\n  query EnvironmentToken {\n    environmentToken {\n      token\n    }\n  }\n':
    types.EnvironmentTokenDocument,
  '\n  query OrderByID($id: ID!) {\n    orderByID(id: $id) {\n      id\n      cartId\n      status\n      marketplaceOrderIds\n      marketplace\n      metadata {\n        ... on AmazonOrderMetadata {\n          account {\n            email\n            groupId\n          }\n        }\n      }\n      shipments {\n        carrierName\n        carrierTrackingNumber\n        carrierTrackingUrl\n        status\n      }\n      lineItems {\n        ... on AmazonLineItem {\n          productId\n          quantity\n          price {\n            value\n            currency\n          }\n          amazonBusinessPrice {\n            value\n            currency\n          }\n        }\n        ... on ShopifyLineItem {\n          quantity\n          variantId\n          productId\n        }\n      }\n      returns {\n        id\n        shippingLabelUrl\n        marketplaceOrderId\n        marketplace\n        orderId\n        lineItems {\n          ... on AmazonReturnLineItem {\n            productId\n            status\n            quantity\n          }\n          ... on ShopifyReturnLineItem {\n            variantId\n            quantity\n            status\n          }\n        }\n      }\n      requiredActions {\n        __typename\n      }\n      events {\n        __typename\n        id\n        createdAt\n        ... on OrderFailedOrderEvent {\n          reason\n        }\n      }\n    }\n  }\n':
    types.OrderByIdDocument,
  '\n  mutation CreateOrder_RequestProductByURL($input: RequestProductByURLInput!) {\n    requestProductByURL(input: $input) {\n      productID\n    }\n  }\n':
    types.CreateOrder_RequestProductByUrlDocument,
  '\n  query CreateOrder_ProductById($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      title\n      description\n      isAvailable\n      images {\n        url\n      }\n      marketplace\n      price {\n        displayValue\n        value\n        currency\n      }\n      variants {\n        id\n        ... on ShopifyVariant {\n          name\n          title\n          isAvailable\n          priceCents\n          image {\n            url\n          }\n        }\n      }\n      vendor\n    }\n  }\n':
    types.CreateOrder_ProductByIdDocument,
  '\n  fragment CreateOrder_priceInfo on Price {\n    currency\n    displayValue\n    value\n  }\n\n  fragment CreateOrder_cartDetails on CartResponse {\n    cart {\n      id\n      buyerIdentity {\n        firstName\n        lastName\n        address1\n        city\n        provinceCode\n        postalCode\n      }\n      cost {\n        subtotal {\n          ...CreateOrder_priceInfo\n        }\n        tax {\n          ...CreateOrder_priceInfo\n        }\n        shipping {\n          ...CreateOrder_priceInfo\n        }\n        total {\n          ...CreateOrder_priceInfo\n        }\n      }\n      stores {\n        ... on AmazonStore {\n          store\n          errors {\n            code\n            message\n          }\n          offer {\n            errors {\n              code\n              message\n            }\n            shippingMethods {\n              id\n            }\n          }\n          shipsToCountries\n        }\n        ... on ShopifyStore {\n          store\n          errors {\n            code\n            message\n          }\n          offer {\n            errors {\n              code\n              message\n            }\n            shippingMethods {\n              id\n            }\n          }\n          shipsToCountries\n        }\n      }\n    }\n    errors {\n      code\n      message\n    }\n  }\n':
    types.CreateOrder_PriceInfoFragmentDoc,
  '\n  mutation CreateOrder_CreateCart($input: CartCreateInput!) {\n    createCart(input: $input) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n':
    types.CreateOrder_CreateCartDocument,
  '\n  query CreateOrder_GetCart($id: ID!) {\n    getCart(id: $id) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n':
    types.CreateOrder_GetCartDocument,
  '\n  mutation CreateOrder_UpdateCartBuyerIdentity($input: CartBuyerIdentityUpdateInput!) {\n    updateCartBuyerIdentity(input: $input) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n':
    types.CreateOrder_UpdateCartBuyerIdentityDocument,
  '\n  mutation CreateOrder_SubmitCart($input: CartSubmitInput!) {\n    submitCart(input: $input) {\n      cart {\n        stores {\n          errors {\n            code\n            message\n          }\n          orderId\n        }\n      }\n      errors {\n        code\n        message\n      }\n    }\n  }\n':
    types.CreateOrder_SubmitCartDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ShopifyAppInstallationLink($storeCanonicalDomain: String!) {\n    shopifyApp {\n      installationLink(storeCanonicalDomain: $storeCanonicalDomain) {\n        canonicalDomain\n        url\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ShopifyAppInstallationLink($storeCanonicalDomain: String!) {\n    shopifyApp {\n      installationLink(storeCanonicalDomain: $storeCanonicalDomain) {\n        canonicalDomain\n        url\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RequestProductByURL($input: RequestProductByURLInput!) {\n    requestProductByURL(input: $input) {\n      productID\n    }\n  }\n',
): (typeof documents)['\n  mutation RequestProductByURL($input: RequestProductByURLInput!) {\n    requestProductByURL(input: $input) {\n      productID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RequestStoreByURL($input: RequestStoreByURLInput!) {\n    requestStoreByURL(input: $input) {\n      requestID\n    }\n  }\n',
): (typeof documents)['\n  mutation RequestStoreByURL($input: RequestStoreByURLInput!) {\n    requestStoreByURL(input: $input) {\n      requestID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RequestShopifyProductByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      marketplace\n      title\n      description\n      vendor\n      url\n      images {\n        url\n      }\n      variants {\n        title\n        image {\n          url\n          ... on ShopifyImage {\n            position\n            width\n            height\n          }\n        }\n        ... on ShopifyVariant {\n          id\n        }\n      }\n      price {\n        value\n        currency\n        displayValue\n      }\n      ... on ShopifyProduct {\n        id\n        marketplace\n        storeCanonicalURL\n        title\n        description\n        vendor\n        url\n        price {\n          value\n          currency\n          displayValue\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RequestShopifyProductByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      marketplace\n      title\n      description\n      vendor\n      url\n      images {\n        url\n      }\n      variants {\n        title\n        image {\n          url\n          ... on ShopifyImage {\n            position\n            width\n            height\n          }\n        }\n        ... on ShopifyVariant {\n          id\n        }\n      }\n      price {\n        value\n        currency\n        displayValue\n      }\n      ... on ShopifyProduct {\n        id\n        marketplace\n        storeCanonicalURL\n        title\n        description\n        vendor\n        url\n        price {\n          value\n          currency\n          displayValue\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RequestMarketplaceProductVendorByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      vendor\n    }\n  }\n',
): (typeof documents)['\n  query RequestMarketplaceProductVendorByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      vendor\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RequestAmazonProductByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      marketplace\n      title\n      description\n      vendor\n      url\n      images {\n        url\n      }\n      variants {\n        title\n        image {\n          url\n        }\n      }\n      price {\n        value\n        currency\n        displayValue\n      }\n      ... on AmazonProduct {\n        id\n        marketplace\n        title\n        description\n        vendor\n        url\n        price {\n          value\n          currency\n          displayValue\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RequestAmazonProductByID($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      marketplace\n      title\n      description\n      vendor\n      url\n      images {\n        url\n      }\n      variants {\n        title\n        image {\n          url\n        }\n      }\n      price {\n        value\n        currency\n        displayValue\n      }\n      ... on AmazonProduct {\n        id\n        marketplace\n        title\n        description\n        vendor\n        url\n        price {\n          value\n          currency\n          displayValue\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EnvironmentToken {\n    environmentToken {\n      token\n    }\n  }\n',
): (typeof documents)['\n  query EnvironmentToken {\n    environmentToken {\n      token\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OrderByID($id: ID!) {\n    orderByID(id: $id) {\n      id\n      cartId\n      status\n      marketplaceOrderIds\n      marketplace\n      metadata {\n        ... on AmazonOrderMetadata {\n          account {\n            email\n            groupId\n          }\n        }\n      }\n      shipments {\n        carrierName\n        carrierTrackingNumber\n        carrierTrackingUrl\n        status\n      }\n      lineItems {\n        ... on AmazonLineItem {\n          productId\n          quantity\n          price {\n            value\n            currency\n          }\n          amazonBusinessPrice {\n            value\n            currency\n          }\n        }\n        ... on ShopifyLineItem {\n          quantity\n          variantId\n          productId\n        }\n      }\n      returns {\n        id\n        shippingLabelUrl\n        marketplaceOrderId\n        marketplace\n        orderId\n        lineItems {\n          ... on AmazonReturnLineItem {\n            productId\n            status\n            quantity\n          }\n          ... on ShopifyReturnLineItem {\n            variantId\n            quantity\n            status\n          }\n        }\n      }\n      requiredActions {\n        __typename\n      }\n      events {\n        __typename\n        id\n        createdAt\n        ... on OrderFailedOrderEvent {\n          reason\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query OrderByID($id: ID!) {\n    orderByID(id: $id) {\n      id\n      cartId\n      status\n      marketplaceOrderIds\n      marketplace\n      metadata {\n        ... on AmazonOrderMetadata {\n          account {\n            email\n            groupId\n          }\n        }\n      }\n      shipments {\n        carrierName\n        carrierTrackingNumber\n        carrierTrackingUrl\n        status\n      }\n      lineItems {\n        ... on AmazonLineItem {\n          productId\n          quantity\n          price {\n            value\n            currency\n          }\n          amazonBusinessPrice {\n            value\n            currency\n          }\n        }\n        ... on ShopifyLineItem {\n          quantity\n          variantId\n          productId\n        }\n      }\n      returns {\n        id\n        shippingLabelUrl\n        marketplaceOrderId\n        marketplace\n        orderId\n        lineItems {\n          ... on AmazonReturnLineItem {\n            productId\n            status\n            quantity\n          }\n          ... on ShopifyReturnLineItem {\n            variantId\n            quantity\n            status\n          }\n        }\n      }\n      requiredActions {\n        __typename\n      }\n      events {\n        __typename\n        id\n        createdAt\n        ... on OrderFailedOrderEvent {\n          reason\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateOrder_RequestProductByURL($input: RequestProductByURLInput!) {\n    requestProductByURL(input: $input) {\n      productID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateOrder_RequestProductByURL($input: RequestProductByURLInput!) {\n    requestProductByURL(input: $input) {\n      productID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CreateOrder_ProductById($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      title\n      description\n      isAvailable\n      images {\n        url\n      }\n      marketplace\n      price {\n        displayValue\n        value\n        currency\n      }\n      variants {\n        id\n        ... on ShopifyVariant {\n          name\n          title\n          isAvailable\n          priceCents\n          image {\n            url\n          }\n        }\n      }\n      vendor\n    }\n  }\n',
): (typeof documents)['\n  query CreateOrder_ProductById($input: ProductByIDInput!) {\n    productByID(input: $input) {\n      id\n      title\n      description\n      isAvailable\n      images {\n        url\n      }\n      marketplace\n      price {\n        displayValue\n        value\n        currency\n      }\n      variants {\n        id\n        ... on ShopifyVariant {\n          name\n          title\n          isAvailable\n          priceCents\n          image {\n            url\n          }\n        }\n      }\n      vendor\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CreateOrder_priceInfo on Price {\n    currency\n    displayValue\n    value\n  }\n\n  fragment CreateOrder_cartDetails on CartResponse {\n    cart {\n      id\n      buyerIdentity {\n        firstName\n        lastName\n        address1\n        city\n        provinceCode\n        postalCode\n      }\n      cost {\n        subtotal {\n          ...CreateOrder_priceInfo\n        }\n        tax {\n          ...CreateOrder_priceInfo\n        }\n        shipping {\n          ...CreateOrder_priceInfo\n        }\n        total {\n          ...CreateOrder_priceInfo\n        }\n      }\n      stores {\n        ... on AmazonStore {\n          store\n          errors {\n            code\n            message\n          }\n          offer {\n            errors {\n              code\n              message\n            }\n            shippingMethods {\n              id\n            }\n          }\n          shipsToCountries\n        }\n        ... on ShopifyStore {\n          store\n          errors {\n            code\n            message\n          }\n          offer {\n            errors {\n              code\n              message\n            }\n            shippingMethods {\n              id\n            }\n          }\n          shipsToCountries\n        }\n      }\n    }\n    errors {\n      code\n      message\n    }\n  }\n',
): (typeof documents)['\n  fragment CreateOrder_priceInfo on Price {\n    currency\n    displayValue\n    value\n  }\n\n  fragment CreateOrder_cartDetails on CartResponse {\n    cart {\n      id\n      buyerIdentity {\n        firstName\n        lastName\n        address1\n        city\n        provinceCode\n        postalCode\n      }\n      cost {\n        subtotal {\n          ...CreateOrder_priceInfo\n        }\n        tax {\n          ...CreateOrder_priceInfo\n        }\n        shipping {\n          ...CreateOrder_priceInfo\n        }\n        total {\n          ...CreateOrder_priceInfo\n        }\n      }\n      stores {\n        ... on AmazonStore {\n          store\n          errors {\n            code\n            message\n          }\n          offer {\n            errors {\n              code\n              message\n            }\n            shippingMethods {\n              id\n            }\n          }\n          shipsToCountries\n        }\n        ... on ShopifyStore {\n          store\n          errors {\n            code\n            message\n          }\n          offer {\n            errors {\n              code\n              message\n            }\n            shippingMethods {\n              id\n            }\n          }\n          shipsToCountries\n        }\n      }\n    }\n    errors {\n      code\n      message\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateOrder_CreateCart($input: CartCreateInput!) {\n    createCart(input: $input) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n',
): (typeof documents)['\n  mutation CreateOrder_CreateCart($input: CartCreateInput!) {\n    createCart(input: $input) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CreateOrder_GetCart($id: ID!) {\n    getCart(id: $id) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n',
): (typeof documents)['\n  query CreateOrder_GetCart($id: ID!) {\n    getCart(id: $id) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateOrder_UpdateCartBuyerIdentity($input: CartBuyerIdentityUpdateInput!) {\n    updateCartBuyerIdentity(input: $input) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n',
): (typeof documents)['\n  mutation CreateOrder_UpdateCartBuyerIdentity($input: CartBuyerIdentityUpdateInput!) {\n    updateCartBuyerIdentity(input: $input) {\n      ...CreateOrder_cartDetails\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateOrder_SubmitCart($input: CartSubmitInput!) {\n    submitCart(input: $input) {\n      cart {\n        stores {\n          errors {\n            code\n            message\n          }\n          orderId\n        }\n      }\n      errors {\n        code\n        message\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateOrder_SubmitCart($input: CartSubmitInput!) {\n    submitCart(input: $input) {\n      cart {\n        stores {\n          errors {\n            code\n            message\n          }\n          orderId\n        }\n      }\n      errors {\n        code\n        message\n      }\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
