import { useQuery } from '@apollo/client/react';
import { getRequestShopifyProductInputById, REQUEST_SHOPIFY_PRODUCT_BY_ID } from 'app/graphql';
import { graphQLClient } from 'app/graphql/client';
import './styles.scss';
import { RequestShopifyProductByIdQuery } from 'app/graphql/generated/cart/graphql';
import { formatPrice } from 'utils/formatters';

const DEFAULT_QUANTITY = 1;

interface ItemCatalogCardValue {
  variantId: string;
  quantity: number;
  productId?: string;
  price?: number;
  currency: string | undefined;
}

export const ShopifyItemCatalogCard = (props: ItemCatalogCardValue) => {
  const shopifyProductQuery = useQuery<RequestShopifyProductByIdQuery>(
    REQUEST_SHOPIFY_PRODUCT_BY_ID,
    {
      variables: getRequestShopifyProductInputById(String(props.productId)),
      skip: !props.productId || graphQLClient.authToken === null,
    },
  );

  const shopifyProduct = shopifyProductQuery.data?.productByID;

  if (shopifyProductQuery.loading || shopifyProduct?.__typename !== 'ShopifyProduct') {
    return null;
  }

  if (!shopifyProduct) {
    return null;
  }

  const productUrl = shopifyProduct.storeCanonicalURL + shopifyProduct.url;

  return (
    <tr>
      <td>
        <img
          className="item-catalog-product-image"
          alt={shopifyProduct.title}
          src={shopifyProduct.images.length > 0 ? shopifyProduct.images[0].url : ''}
        />
      </td>
      <td>
        <div className="item-catalog-product-description">
          <div className="item-catalog-product-description-row">
            <b>Name</b>
            <div>{shopifyProduct.title}</div>
          </div>
          <div className="item-catalog-product-description-row">
            <b>Variant</b>
            <div>
              {
                shopifyProduct.variants.find(
                  (v) => v.__typename === 'ShopifyVariant' && v.id === props.variantId,
                )?.title
              }
            </div>
          </div>
          <div className="item-catalog-product-description-row">
            <b>Description</b>
            <div>{shopifyProduct.description}</div>
          </div>
          <div className="item-catalog-product-description-row">
            <b>Product Link</b>
            <div>
              <a href={productUrl} target="_blank" rel="noreferrer">
                {productUrl}
              </a>
            </div>
          </div>
        </div>
      </td>
      <td>{props.quantity ?? DEFAULT_QUANTITY}</td>
      <td>
        {props.price != null && props.currency
          ? formatPrice(props.price / 100, props.currency)
          : shopifyProduct.price?.displayValue ?? 'N/A'}
      </td>
    </tr>
  );
};
