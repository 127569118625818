import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import AmazonBusiness from 'components/FormRequestBlock/AmazonBusiness';
import ApiKey from 'components/FormRequestBlock/ApiKey';
import MarginAmount from 'components/FormRequestBlock/MarginAmount';
import SpreedlyConfig from 'components/FormRequestBlock/SpreedlyConfig';
import WebhooksUrl from 'components/FormRequestBlock/WebhooksUrl';
import JWTValidationKey from 'components/FormRequestBlock/JWTValidationKey';

import './style.scss';

const Page = () => {
  const { spreedly } = useAppSelector(selectUserInfo) || {};

  return (
    <div className="account-container">
      <h2 className="section-header">Rye API Authentication</h2>
      <ApiKey />
      {spreedly && <SpreedlyConfig />}

      <h2 className="section-header">JWT Authentication</h2>
      <JWTValidationKey />

      <h2 className="section-header">Webhooks</h2>
      <WebhooksUrl />

      <h2 className="section-header">Margin</h2>
      <MarginAmount />

      <>
        <h2 className="section-header">Amazon Business</h2>
        <AmazonBusiness />
      </>
    </div>
  );
};

export default Page;
