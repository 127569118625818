import {
  where as firebaseWhere,
  orderBy as firebaseOrderBy,
  limit as firebaseLimit,
} from 'firebase/firestore';
import type { CheckoutRequestFirestore } from '@rye-com/rye-data-layer';

import { CollectionId, FirebaseCollection } from 'app/firebase';
import { StepName } from 'components/AccordionItem';

import {
  AmazonBusinessAccount,
  BillingNotifications,
  BillingPerGmv,
  BillingPerPeriod,
  BillingPrePayment,
  InventoryRequestLimits,
  Spreedly,
  StripeConfig,
} from 'app/graphql/generated/admin/graphql';

export type CheckoutRequestFirestoreModel = CheckoutRequestFirestore & {
  autoCancelTask: {
    name: string;
  };
};

const DEFAULT_CHECKOUT_REQUESTS_ROWS_LIMIT = 1000;

export enum AuthProvider {
  Local = 'local',
  Google = 'google',
  Firebase = 'firebase',
}

interface OnboardingTasksCompleteStatus {
  [StepName.ExploreDemo]: boolean;
  [StepName.Tutorial]: boolean;
  [StepName.ResourceDocs]: boolean;
  [StepName.BookACall]: boolean;
  [StepName.Community]: boolean;
}

export interface UserModel {
  firstName?: string | null;
  lastName?: string | null;
  displayName?: string | null;
  apiKey: string;
  adminToken?: string | null;
  authProvider: AuthProvider;
  email: string | null;
  uid: string;
  webhookURL: string;
  webhookURLVerified?: boolean;
  marginAmount: number;
  marginIsPercent: boolean; // $ = any > 0, % = 0-100
  photoURL?: string | null;
  isApprovedForDirectCheckout?: boolean | null;
  onboardingTasksStatus?: OnboardingTasksCompleteStatus;
  onBoardingPresentationComplete?: boolean;
  spreedly?: Spreedly | null;
  amazonBusinessAccount?: AmazonBusinessAccount | null;
  stripe?: StripeConfig | null;
  website?: string | null;
  firstRequestAt?: {
    nanoseconds: number;
    seconds: number;
  } | null;
  inventoryRequestLimits?: InventoryRequestLimits | null;
  jwtValidationKey?: string | null;
  hmacSecretKey: string;
  createdAt?: {
    nanoseconds: number;
    seconds: number;
  } | null;
  billingPeriodSettings?: BillingPerPeriod | BillingPerGmv | BillingPrePayment | null;
  isReadOnly: boolean;
  billingNotificationSettings?: BillingNotifications;
}

class UsersCollection extends FirebaseCollection {
  constructor() {
    super(CollectionId.Users);
  }

  updateWebhookURL = async (userUid: string, webhookURL: string) => {
    const condition = firebaseWhere('id', '==', userUid);
    return await this.update<UserModel>(condition, { webhookURL });
  };

  updateJwtValidationKey = async (userUid: string, jwtValidationKey: string) => {
    const condition = firebaseWhere('id', '==', userUid);
    return await this.update<UserModel>(condition, { jwtValidationKey });
  };

  updateMarginAmount = async (userUid: string, marginAmount: number, marginIsPercent: boolean) => {
    console.log(userUid, marginAmount, marginIsPercent);
    const condition = firebaseWhere('id', '==', userUid);
    return await this.update<UserModel>(condition, { marginAmount, marginIsPercent });
  };

  updateOnboardingTasksStatus = async (
    userUid: string,
    onboardingTasksStatus: OnboardingTasksCompleteStatus,
  ) => {
    const condition = firebaseWhere('id', '==', userUid);
    return await this.update<UserModel>(condition, {
      onboardingTasksStatus: onboardingTasksStatus,
    });
  };

  fetchCheckoutRequestsByUid = async (
    userUid: string,
  ): Promise<CheckoutRequestFirestoreModel[]> => {
    const resp =
      (await this.list<CheckoutRequestFirestoreModel>({
        collectionIdAddon: `/${userUid}/checkoutRequests`,
        limit: firebaseLimit(DEFAULT_CHECKOUT_REQUESTS_ROWS_LIMIT),
        orderBy: firebaseOrderBy('createdAt', 'desc'),
      })) || [];

    return resp.map((item) => item.data);
  };

  fetchCheckoutRequestByOrderId = async (
    userUid: string,
    orderId: string,
  ): Promise<CheckoutRequestFirestoreModel | undefined> => {
    const resp = await this.get<CheckoutRequestFirestoreModel>(
      `/${userUid}/checkoutRequests/${orderId}`,
    );

    return resp;
  };
}

export const usersCollection = new UsersCollection();
