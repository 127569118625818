import * as Sentry from '@sentry/react';
import { useAuthState } from 'react-firebase-hooks/auth';

import authAPI from 'app/auth';

export function SyncUserWithSentry() {
  useAuthState(authAPI.auth, {
    onUserChanged: async (user) => {
      if (user) {
        Sentry.setUser({
          id: user.uid,
          email: user.email ?? undefined,
          username: user.displayName ?? undefined,
        });
      } else {
        Sentry.setUser(null);
      }
    },
  });

  return null;
}
