import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import RyeLogo from 'components/Logo';
import { useAuth } from 'providers/AuthProvider';
import { defaultLoginRedirectFlow, useQuery } from 'routes';
import { ReactComponent as GoogleIcon } from 'assets/icons/social/google.svg';
import config, { Routes } from 'config/constants';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import Separator from 'components/Separator';

import './style.scss';
import { SOURCE, ACTION } from 'app/analytics';
import { trackSegmentEvent } from 'app/utils';
import { GoogleSignUpFlow } from './GoogleSignUpFlow';
import { addProtocolIfNotPresent } from 'utils/addProtocolIfNotPresent';

const DEFAULT_VALUE = '';

export enum RegisterFormState {
  INITIAL = 'initial',
  USER_CHOSE_GOOGLE = 'userChoseGoogle',
  SUBMITTING_EMAIL = 'submittingEmail',
}

const RegisterPage = () => {
  const refInputPassword = useRef<HTMLInputElement | null>(null);
  const allQueryParams = useQuery();
  const queryRedirect = allQueryParams.get('redirect');
  const queryEmail = allQueryParams.get('email');

  // Form state
  const [formState, setFormState] = useState<RegisterFormState>(RegisterFormState.INITIAL);

  // Form fields
  const [firstName, setFirstName] = useState(DEFAULT_VALUE);
  const [lastName, setLastName] = useState(DEFAULT_VALUE);
  const [email, setEmail] = useState(queryEmail || DEFAULT_VALUE);
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState(DEFAULT_VALUE);

  const { search } = useLocation();
  const loginLink = search ? `${Routes.Login}${search}` : Routes.Login;
  const navigate = useNavigate();

  const { user, error, loading, signInOrRegisterWithGoogle, registerWithEmailAndPassword } =
    useAuth();

  useEffect(() => {
    const fallback = () => {
      if (user) {
        navigate(config.routeDefaultHome);

        return;
      }
    };

    focusOnPassword(!!queryEmail);

    defaultLoginRedirectFlow(
      navigate,
      loading,
      user,
      queryRedirect,
      fallback,
      allQueryParams ? `?${allQueryParams}` : '',
    );
  }, [user, loading, navigate, queryRedirect, queryEmail]);

  useEffect(() => {
    error && alert(error);

    setFormState(RegisterFormState.INITIAL);
  }, [error]);

  const focusOnPassword = (hasEmailQuery: boolean) => {
    if (hasEmailQuery && refInputPassword && refInputPassword.current) {
      refInputPassword.current.focus();
    }
  };

  const handleOnSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Update UI to show that we're in the process of submitting
    setFormState(RegisterFormState.SUBMITTING_EMAIL);

    await registerWithEmailAndPassword(firstName, lastName, email, website, password);

    trackSegmentEvent(SOURCE.SIGNUP_FORM, ACTION.CLICK, 'register_with_email_button', {
      email,
      firstName,
      lastName,
    });
  };

  if (loading || (user && queryRedirect)) return null;

  return (
    <div className="sign-up">
      {/* left section */}
      <div className="sign-up-container">
        <RyeLogo to={Routes.Login} />
        <p>Sign up for a Rye account to start building the future of eCommerce</p>
        <div className="sign-up-account-link">
          Already have an account? <Link to={loginLink}>Sign in</Link>
        </div>
      </div>

      {/* right section */}
      <div className="sign-up-container">
        <div className="sign-up-header">Create your account</div>

        {formState === RegisterFormState.USER_CHOSE_GOOGLE ? (
          // If the user wants to sign up with Google, we need to collect additional info first
          <GoogleSignUpFlow
            setFormState={setFormState}
            signInOrRegisterWithGoogle={signInOrRegisterWithGoogle}
          />
        ) : (
          <>
            {/* Trigger the Google sign-up flow */}
            <FormButton
              blank
              className="sign-up-google"
              onClick={() => setFormState(RegisterFormState.USER_CHOSE_GOOGLE)}
            >
              <GoogleIcon /> Sign up with Google
            </FormButton>

            <Separator text="or" />

            {/* Email sign-up form */}
            <form className="sign-up-form" onSubmit={handleOnSubmit}>
              <FormInput
                allowWhitespace
                className="sign-up-input"
                placeholder="First name"
                type="text"
                name="first-name"
                value={firstName}
                onChange={setFirstName}
                required
              />
              <FormInput
                allowWhitespace
                className="sign-up-input"
                placeholder="Last name"
                type="text"
                name="last-name"
                value={lastName}
                onChange={setLastName}
                required
              />
              <FormInput
                className="sign-up-input"
                placeholder="Work email address"
                type="email"
                name="email"
                value={email}
                onChange={setEmail}
                required
              />
              <FormInput
                className="sign-up-input"
                placeholder="Website"
                type="url"
                name="website"
                value={website}
                onBlur={() => setWebsite(addProtocolIfNotPresent(website))}
                onChange={setWebsite}
                required
              />
              <FormInput
                inputRef={refInputPassword}
                className="sign-up-input"
                placeholder="Password"
                type="password"
                name="password"
                value={password}
                onChange={setPassword}
                required
              />

              <FormButton type="submit" disabled={formState === RegisterFormState.SUBMITTING_EMAIL}>
                {formState === RegisterFormState.SUBMITTING_EMAIL
                  ? 'Signing up...'
                  : 'Sign up with email'}
              </FormButton>
            </form>
          </>
        )}

        <small className="sign-up-terms">
          By clicking "Sign up with Google/email" above, you acknowledge that you have read and
          understood, and agree to Rye's{' '}
          <a href={config.external.terms} target="_blank">
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href={config.external.privacyPolicy} target="_blank">
            Privacy Policy
          </a>
          .
        </small>
      </div>
    </div>
  );
};

export default RegisterPage;
