import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import authAPI from 'app/auth';
import { fetchUserInfo, cleanUserInfo } from 'app/store/user';
import { useAppDispatch } from 'app/store';
import {
  cleanInventoryRequests,
  fetchInventoryRequests,
  fetchProductRequestCount,
  fetchStoreRequestCount,
} from 'app/store/inventoryRequests';
import { cleanCheckoutRequests, fetchCheckoutRequests } from 'app/store/checkoutRequests';
import { fetchClientIp } from '../app/store/clientIp';
import { User } from 'firebase/auth';

type AuthProviderState = {
  user: User | null | undefined;
  loading: boolean;
  error: Error | string | undefined;
  logout: () => void;
  signInOrRegisterWithGoogle: (website?: string | undefined) => Promise<void>;
  logInWithEmailAndPassword: (email: string, password: string) => void;
  registerWithEmailAndPassword: (
    firstName: string,
    lastName: string,
    email: string,
    website: string,
    password: string,
  ) => void;
  sendPasswordReset: (email: string) => void;
  assumeDeveloperIdentity: (
    developerId: string,
    apiKey: string | undefined,
    userToken: string | undefined | null,
    readOnly?: boolean,
  ) => void;
};

const initialState: AuthProviderState = {
  user: null,
  loading: false,
  error: undefined,
  logout: () => null,
  signInOrRegisterWithGoogle: () => Promise.resolve(),
  logInWithEmailAndPassword: () => null,
  registerWithEmailAndPassword: () => null,
  sendPasswordReset: () => null,
  assumeDeveloperIdentity: () => null,
};

const AuthContext = createContext<AuthProviderState>(initialState);

type AuthError = Error | string | undefined;

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, loading, error] = useAuthState(authAPI.auth);
  const [userCreationInProgress, setUserCreationInProgress] = useState(false);
  const [authError, setAuthError] = useState<AuthError>(error);
  const websiteRef = useRef<string | undefined>();
  const dispatch = useAppDispatch();

  const setAuthErrorWithTimer = (error: string | undefined) => {
    if (error) {
      setAuthError(error);
      setTimeout(() => setAuthError(''), 1000); // reset state of error to trigger next error
    }
  };

  // Register with Google account, or sign in if account already exists
  const signInOrRegisterWithGoogle = async (website?: string) => {
    websiteRef.current = website;
    const { error } = await authAPI.signInOrRegisterWithGoogle();

    if (error) {
      setAuthErrorWithTimer(error);
    }
  };

  const logInWithEmailAndPassword = async (email: string, password: string) => {
    const { error } = await authAPI.logInWithEmailAndPassword(email, password);
    setAuthErrorWithTimer(error);
  };

  const assumeDeveloperIdentity = async (
    developerId: string,
    apiKey: string | undefined,
    userToken: string | undefined | null,
    readOnly = true,
  ) => {
    const { error } = await authAPI.assumeDeveloperIdentity(
      developerId,
      apiKey,
      userToken,
      readOnly,
    );
    setAuthErrorWithTimer(error);
  };

  const registerWithEmailAndPassword = async (
    firstName: string,
    lastName: string,
    email: string,
    website: string,
    password: string,
  ) => {
    setUserCreationInProgress(true);
    try {
      const { error } = await authAPI.registerWithEmailAndPassword({
        firstName,
        lastName,
        website,
        email,
        password,
      });

      if (error) {
        setAuthErrorWithTimer(error);
      }
    } finally {
      setUserCreationInProgress(false);
    }
  };

  const sendPasswordReset = async (email: string) => {
    const { error } = await authAPI.sendPasswordReset(email);
    setAuthErrorWithTimer(error);
  };

  const logout = async () => {
    authAPI.logout();

    dispatch(cleanUserInfo());
    dispatch(cleanInventoryRequests());
    dispatch(cleanCheckoutRequests());
  };

  useEffect(() => {
    if (user && !userCreationInProgress) {
      (async () => {
        dispatch(fetchUserInfo(user.uid, user, websiteRef.current));
        dispatch(fetchInventoryRequests(user.uid));
        dispatch(fetchCheckoutRequests(user.uid));
        dispatch(fetchStoreRequestCount(user.uid));
        dispatch(fetchProductRequestCount(user.uid));
        dispatch(fetchClientIp());
      })();
    }
  }, [user, userCreationInProgress, dispatch]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error: authError,
        logout,
        signInOrRegisterWithGoogle,
        logInWithEmailAndPassword,
        registerWithEmailAndPassword,
        sendPasswordReset,
        assumeDeveloperIdentity,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
