import { useCallback, useEffect, useRef, useState } from 'react';

export function useCopyToClipboard() {
  const [didCopy, setDidCopy] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Clean up timeout on unmount
  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  const copy = useCallback((text: string) => {
    try {
      navigator.clipboard.writeText(text);
      
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      
      setDidCopy(true);
      timeout.current = setTimeout(() => {
        setDidCopy(false);
      }, 1_500);
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  }, []);

  return { copy, didCopy };
}
