import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import authAPI from 'app/auth';
import Layout from 'components/Layout';
import { defaultLoginRedirectFlow } from 'routes';
import { trackSegmentPageEvent } from 'app/utils';

interface PrivateRouteProps {
  children: JSX.Element;
  fitted?: boolean;
  noLayout?: boolean;
}

const Loader = () => null;

export const PrivateRoute = ({
  children,
  fitted,
  noLayout,
}: PrivateRouteProps): JSX.Element | null => {
  const [user, loading] = useAuthState(authAPI.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const queryRedirect = searchParams.get('redirect');
  const allQueryParams = searchParams.toString();

  trackSegmentPageEvent(location.pathname);

  useEffect(() => {
    const fallback = () => {
      if (!user && queryRedirect) {
        navigate(`/login?redirect=${queryRedirect}`);
        return;
      } else if (!user) {
        if (location.pathname !== '/login' && location.pathname !== '/dashboard') {
          searchParams.append('redirect', location.pathname);
        }
        navigate({
          pathname: '/login',
          search: searchParams.toString(),
        });
        return;
      }
    };

    defaultLoginRedirectFlow(
      navigate,
      loading,
      user,
      queryRedirect,
      fallback,
      allQueryParams ? `?${allQueryParams}` : '',
    );
  }, [user, loading, navigate, queryRedirect, allQueryParams]);

  return loading || queryRedirect ? (
    <Loader />
  ) : noLayout ? (
    <>{children}</>
  ) : (
    <>
      <Layout fitted={fitted}>{children}</Layout>
    </>
  );
};
