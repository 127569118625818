import React from 'react';
import { NavigateFunction, useLocation, Navigate, useRoutes, RouteObject } from 'react-router-dom';

import config, { Routes } from 'config/constants';
import AccountPage from 'pages/Account';
import RequestsPage from 'pages/Requests';
import GraphqlPage from 'pages/Graphql';
import ResourcesPage from 'pages/Resources';
import OrdersPage from 'pages/Orders';
import { AffiliateDisclosurePage } from 'pages/AffiliateDisclosure';
import LoginPage from 'pages/Auth/Login';
import RegisterPage from 'pages/Auth/Register';
import BillingPage from 'pages/Billing';
import ResetPage from 'pages/Auth/Reset';
import ErrorPage, { Types } from 'pages/Error';
import { PrivateRoute } from 'routes/PrivateRoute';
import { User } from 'firebase/auth';
import { OrderDetails } from 'components/Orders/orderDetails';
import DashboardPage from 'pages/Dashboard';
import { LoaderWrapper } from 'components/LoaderWrapper';
import { useHypertune } from 'generated/hypertune.react';
import CreateOrderPage from 'pages/CreateOrder';
import MerchantsPage from 'pages/Merchants';

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const defaultLoginRedirectFlow = (
  navigate: NavigateFunction,
  loading: boolean,
  user: User | null | undefined,
  queryRedirect?: string | null,
  fallback?: () => void,
  search?: string,
): void => {
  if (loading) {
    return;
  } else if (user && queryRedirect) {
    const urlParams = new URLSearchParams(search);
    urlParams.delete('redirect');
    navigate({ pathname: queryRedirect, search: urlParams.toString() }, { replace: true });
    return;
  } else if (fallback) {
    fallback();
  }
};

const ROUTES: RouteObject[] = [
  // Wildcard error page (404, etc)
  { path: Routes.All, element: <ErrorPage type={Types.NotFound} /> },

  // Legal-related pages
  { path: Routes.Affiliate, element: <AffiliateDisclosurePage /> },

  // Auth-related pages
  { path: Routes.Login, element: <LoginPage /> },
  { path: Routes.Register, element: <RegisterPage /> },
  { path: Routes.Reset, element: <ResetPage /> },

  // Re-route / to /dashboard
  {
    path: Routes.Home,
    element: <Navigate to={config.routeDefaultHome} />,
  },

  // Navbar links
  {
    path: Routes.Dashboard,
    element: (
      <PrivateRoute>
        <LoaderWrapper>
          <DashboardPage />
        </LoaderWrapper>
      </PrivateRoute>
    ),
  },
  {
    path: Routes.Graphql,
    element: (
      <PrivateRoute fitted>
        <GraphqlPage />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.Account,
    element: (
      <PrivateRoute>
        <AccountPage />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.Requests,
    element: (
      <PrivateRoute>
        <RequestsPage />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.Resources,
    element: (
      <PrivateRoute>
        <ResourcesPage />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.Merchants,
    element: (
      <PrivateRoute>
        <MerchantsPage />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.Orders,
    element: (
      <PrivateRoute>
        <OrdersPage />
      </PrivateRoute>
    ),
  },

  // Order details page
  {
    path: Routes.Order,
    element: (
      <PrivateRoute>
        <OrderDetails />
      </PrivateRoute>
    ),
  },
];

const MainRouter = () => {
  const hypertune = useHypertune();
  const routes = ROUTES.slice();

  routes.push({
    path: Routes.Billing,
    element: (
      <PrivateRoute>
        <BillingPage />
      </PrivateRoute>
    ),
  });

  const createOrderFlag = hypertune.viewCreateOrder({ fallback: false });
  if (createOrderFlag) {
    routes.push({
      path: Routes.CreateOrder,
      element: (
        <PrivateRoute>
          <CreateOrderPage />
        </PrivateRoute>
      ),
    });
  }

  return useRoutes(routes);
};

export default MainRouter;
