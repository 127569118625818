import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  Loader2Icon,
  PlusIcon,
  SearchIcon,
} from 'lucide-react';
import { ReactNode, useState } from 'react';
import { toast } from 'sonner';
import { useLazyQuery } from '@apollo/client';

import { getShopifyInstallationLinkInput, SHOPIFY_INSTALLATION_LINK } from 'app/graphql';

import { cn } from 'lib/utils';
import { formatRootGraphQLErrorMessage } from 'lib/formatRootGraphQLErrorMessage';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';

import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

import { MerchantIntegrationStatus } from './MerchantIntegrationStatus';

interface Step {
  children: ReactNode;
  description: ReactNode;
  title: ReactNode;
  canNext: boolean;
}

export function AddMerchantButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [inputUrl, setInputUrl] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { copy, didCopy } = useCopyToClipboard();
  const [query, { data, loading }] = useLazyQuery(SHOPIFY_INSTALLATION_LINK, {
    defaultOptions: {
      fetchPolicy: 'no-cache',
    },
    onCompleted() {
      // Automatically move to step #2
      setCurrentStepIndex(1);
    },
    onError(error) {
      // Map root GraphQL error codes to console-friendly error messages
      const errorMap: Record<string, string | undefined> = {
        CANONICAL_DOMAIN_RESOLUTION_ERROR: 'Could not find a Shopify store at the given URL.',
      };

      for (const graphQLError of error.graphQLErrors) {
        const errorMessage = errorMap[graphQLError.extensions.code as string];
        if (errorMessage) {
          toast.error(errorMessage);
          return;
        }
      }

      // Fallback
      toast.error(formatRootGraphQLErrorMessage(error.message));
    },
  });

  const canonicalDomain = data?.shopifyApp?.installationLink?.canonicalDomain;
  const installationLink = data?.shopifyApp?.installationLink?.url;

  const copyInstallationLink = () => {
    if (installationLink) {
      copy(installationLink);
    }
  };

  const resetState = () => {
    setCurrentStepIndex(0);
    setInputUrl('');
  };

  const steps: Step[] = [
    {
      title: 'Onboard merchant',
      description:
        'To onboard a merchant, you will need to send them an installation link for the Rye connector. Use a different link for each merchant.',
      children: (
        <div className="space-y-2">
          <p>Enter the URL of the merchant's Shopify store below.</p>

          <form
            className="flex items-center gap-2"
            onSubmit={(e) => {
              e.preventDefault();
              query({
                variables: getShopifyInstallationLinkInput(inputUrl),
              });
            }}
          >
            <Input
              disabled={loading}
              placeholder="https://aloyoga.com"
              value={inputUrl}
              onChange={(e) => setInputUrl(e.target.value)}
            />
            <Button disabled={loading} size="icon" type="submit">
              {loading ? (
                <Loader2Icon className="size-4 animate-spin" />
              ) : (
                <SearchIcon className="size-4" />
              )}
            </Button>
          </form>
        </div>
      ),
      canNext: Boolean(installationLink),
    },
    {
      title: 'Send link',
      description: (
        <>
          Here's your link. Send it to the merchant and have them follow the{' '}
          <a
            href="https://docs.rye.com/sync-api/shopify-merchant-onboarding/shopify-app-installation"
            target="_blank"
            rel="noopener noreferrer"
          >
            installation guide
          </a>
          .
        </>
      ),
      children: (
        <Tooltip open={didCopy || undefined}>
          <TooltipTrigger asChild>
            <div
              role="button"
              className="group mx-auto flex max-w-[380px] items-center gap-2 rounded-xl bg-muted px-2 py-1"
              onClick={() => {
                copyInstallationLink();
              }}
            >
              <div className="select-none overflow-hidden truncate text-nowrap text-sm">
                <span>{installationLink}</span>
              </div>

              <Button
                variant="ghost"
                className="transition-all group-active:scale-75"
                size="icon"
                onClick={copyInstallationLink}
              >
                {didCopy ? <CheckIcon className="size-4" /> : <CopyIcon className="size-4" />}
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipContent>{didCopy ? 'Copied to clipboard!' : 'Click to copy'}</TooltipContent>
        </Tooltip>
      ),
      canNext: true,
    },
    {
      title: 'Check status',
      description:
        "Here's the status of the merchant's integration. If it's not installed, you can send them the link again from the previous step.",
      children: canonicalDomain && <MerchantIntegrationStatus canonicalDomain={canonicalDomain} />,
      canNext: true,
    },
  ];

  const currentStep = steps[currentStepIndex];
  const isFinalStep = currentStepIndex === steps.length - 1;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          resetState();
        }

        setIsOpen(newOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button>
          {currentStep.title} <PlusIcon className="ml-1 size-4" />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{currentStep.title}</DialogTitle>
          <DialogDescription>{currentStep.description}</DialogDescription>
        </DialogHeader>

        {currentStep.children}

        <div className="flex items-center justify-between">
          <Button
            variant="outline"
            onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
            disabled={currentStepIndex === 0}
          >
            <ChevronLeftIcon className="mr-2 size-4" /> Back
          </Button>

          <div className="mx-auto flex gap-2">
            {Array.from({ length: 3 }).map((_, i) => (
              <div
                key={i}
                className={cn('h-2 w-2 rounded-full bg-muted transition-all duration-300', {
                  'bg-primary': i === currentStepIndex,
                })}
              />
            ))}
          </div>

          <Button
            onClick={() => {
              if (isFinalStep) {
                setIsOpen(false);
              } else {
                setCurrentStepIndex(currentStepIndex + 1);
              }
            }}
            disabled={!currentStep.canNext}
          >
            {isFinalStep ? (
              <>
                Done <CheckIcon className="ml-2 size-4" />
              </>
            ) : (
              <>
                Next <ChevronRightIcon className="ml-2 size-4" />
              </>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
