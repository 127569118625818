import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import ApolloGQLProvider from 'app/graphql/client';
import { store } from 'app/store';
import MainRoute from 'routes';

import './App.scss';
import { env } from 'config/env';
import { ConfigProvider } from 'antd';
import AppHypertuneProvider from 'providers/AppHypertuneProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import { TooltipProvider } from 'components/ui/tooltip';
import { Toaster } from 'components/ui/sonner';
import { SyncUserWithSentry } from 'components/SyncUserWithSentry';
import { AuthProvider } from 'providers/AuthProvider';

const container = document.getElementById('root');
const root = container && createRoot(container);

Sentry.init({
  dsn: 'https://fdf46288a90649c1b8dfdb93192b75cd@o4504131870916608.ingest.sentry.io/4504234997710848',
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: env.REACT_APP_NODE_ENV,
});

if (!root) {
  throw new Error('Root element (id=root) not found; console is broken and will not render.');
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloGQLProvider>
        <Provider store={store}>
          <SyncUserWithSentry />
          <ConfigProvider
            theme={{
              token: {
                /* here is your global tokens */
                colorPrimary: 'rgb(78, 213, 88)',
                colorPrimaryBorder: 'rgb(78, 213, 88)',
                fontFamily: 'Inter',
              },
            }}
          >
            <ThemeProvider>
              <AuthProvider>
                <AppHypertuneProvider>
                  <TooltipProvider>
                    <MainRoute />
                  </TooltipProvider>
                  <Toaster />
                </AppHypertuneProvider>
              </AuthProvider>
            </ThemeProvider>
          </ConfigProvider>
        </Provider>
      </ApolloGQLProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
