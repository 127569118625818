import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client/react';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';

import { useAppDispatch, useAppSelector } from 'app/store';
import { fetchUserInfo, selectUserInfo } from 'app/store/user';
import { checkAmazonAuth, ryeOAuth } from 'app/amazon/auth';
import { AMAZON_AUTH_URL, MARKETPLACE_URL, oauthRedirectUris } from 'app/amazon/constants';
import { ADMIN_USER, updateAdminUser } from 'app/graphql';
import { env } from 'config/env';
import FormRequestBlock from 'components/FormRequestBlock';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import { AmazonBusinessOauthStatus } from 'app/graphql/generated/admin/graphql';

import './style.scss';
import { useAuth } from 'providers/AuthProvider';
import { v4 } from 'uuid';

const AmazonBusiness = () => {
  // Selectors
  const { adminToken, amazonBusinessAccount, isReadOnly } = useAppSelector(selectUserInfo) || {};

  // State
  const [formLoading, setFormLoading] = useState(true);
  const isConnecting = useRef(false);
  const isRevoking = useRef(false);
  const [inputValue, setInputValue] = useState(amazonBusinessAccount?.groupId ?? '');
  const [amazonAccountEmail, setAmazonAccountEmail] = useState(
    amazonBusinessAccount?.amazonAccountEmail ?? '',
  );
  const [amazonEmail, setAmazonEmail] = useState(amazonBusinessAccount?.buyerEmail ?? '');

  const dispatch = useAppDispatch();
  const { user } = useAuth();

  // Refs
  const codeSet = useRef(false);
  const checkingStatus = useRef(false);
  const authInterval = useRef<ReturnType<typeof setInterval>>();
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const { refetch } = useQuery(ADMIN_USER, {
    skip: !adminToken,
    context: { clientName: 'admin' },
  });

  // Handlers
  const handleAuthentication = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    window.open(MARKETPLACE_URL, '_blank');
    isConnecting.current = true;
    setFormLoading(true);
    pollAuth();
  };

  const validateField = (value: string, fieldName: string): boolean => {
    if (!value.trim()) {
      toast.error(`${fieldName} is required`, { position: 'bottom-right' });
      setFormLoading(false);
      return false;
    }
    return true;
  };

  const handleSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isReadOnly) return;

    setFormLoading(true);
    const data = await refetch();
    const groupId = inputValue.trim();
    const email = amazonEmail.trim();
    const accountEmail = amazonAccountEmail.trim();

    const amazonBusinessAccount = data?.data.user.data?.amazonBusinessAccount;

    if (
      !validateField(accountEmail, 'Amazon account email') ||
      !validateField(groupId, 'Group ID') ||
      !validateField(email, 'Buyer email')
    ) {
      return;
    }

    if (groupId && email && amazonBusinessAccount) {
      try {
        await updateAdminUser({
          adminToken,
          updates: {
            amazonBusinessAccount: { groupId, buyerEmail: email, amazonAccountEmail: accountEmail },
          },
        });
        toast.success('Amazon account details updated successfully', { position: 'bottom-right' });
      } catch (error) {
        toast.error('Amazon config could not be updated', { position: 'bottom-right' });
      }
    }

    loadingTimeout.current = setTimeout(() => setFormLoading(false), 1000);
  };

  const handleRevoke = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isReadOnly) return;

    const url = new URL(AMAZON_AUTH_URL);
    url.searchParams.set('state', v4());

    window.open(url.toString(), '_blank');
    isRevoking.current = true;
    setFormLoading(true);

    pollAuth();
  };

  const pollAuth = () => {
    authInterval.current = setInterval(async () => {
      try {
        await checkAuthStatus();
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        isConnecting.current = false;
        isRevoking.current = false;
        setFormLoading(false);
      }
    }, 1000);

    // Stop polling after 30 seconds
    loadingTimeout.current = setTimeout(() => {
      clearInterval(authInterval.current);
      authInterval.current = undefined;
      setFormLoading(false);
      isConnecting.current = false;
      isRevoking.current = false;
    }, 1000 * 60 * 2);
  };

  // Helper Functions
  const authenticate = async (code: string) => {
    if (!adminToken) return;

    const amazonCallbackUri = searchParams.get('amazon_callback_uri');
    const amazonState = searchParams.get('amazon_state');

    await ryeOAuth({ code, adminToken });
    await checkAmazonAuth(adminToken, null);

    if (amazonCallbackUri && amazonState) {
      const params = new URLSearchParams();
      params.set('status', 'auth_code_use_successful');
      params.set('amazon_state', amazonState);
      window.location.href = `${amazonCallbackUri}?${params.toString()}`;
    }
  };

  const checkAuthStatus = async (showAuthRevokedMessage?: boolean) => {
    checkingStatus.current = true;
    const [data, res] = await Promise.all([refetch(), checkAmazonAuth(adminToken, null)]);
    const amazonBusinessAccount = data?.data.user.data?.amazonBusinessAccount;

    if (
      isRevoking.current &&
      res.data.amazonBusinessAuthStatus.status === AmazonBusinessOauthStatus.Revoked
    ) {
      clearInterval(authInterval.current);
      authInterval.current = undefined;
      setAmazonAccountEmail('');
      isRevoking.current = false;
      setFormLoading(false);

      if (showAuthRevokedMessage) {
        toast.error(
          'Your Amazon Business account is no longer authenticated. Please authenticate again.',
          {
            position: 'bottom-right',
          },
        );
      }
    }

    if (user) {
      dispatch(fetchUserInfo(user.uid, user));
      setAmazonAccountEmail(amazonBusinessAccount?.amazonAccountEmail ?? '');
      setAmazonEmail(amazonBusinessAccount?.buyerEmail ?? '');
      setInputValue(amazonBusinessAccount?.groupId ?? '');
    }

    if (
      isConnecting.current &&
      res.data.amazonBusinessAuthStatus.status === AmazonBusinessOauthStatus.Active
    ) {
      clearInterval(authInterval.current);
      authInterval.current = undefined;

      isConnecting.current = false;
      setFormLoading(false);
    }

    checkingStatus.current = false;
  };

  const handleAuthCodeFlow = async (code: string) => {
    if (!codeSet.current && adminToken) {
      codeSet.current = true;
      setSearchParams();
      await authenticate(code);
    }
  };

  const handleCallbackRedirect = (callbackUri: string, amazonState: string) => {
    const params = new URLSearchParams();
    params.set('state', v4());
    params.set('redirect_uri', oauthRedirectUris[env.REACT_APP_NODE_ENV]);
    params.set('status', 'authentication_successful');
    params.set('amazon_state', amazonState);
    window.location.href = `${callbackUri}?${params.toString()}`;
  };

  useEffect(() => {
    const cleanup = () => {
      clearInterval(authInterval.current);
      clearTimeout(loadingTimeout.current);
    };

    if (adminToken) {
      const code = searchParams.get('code') ?? searchParams.get('auth_code');
      const callbackUri = searchParams.get('amazon_callback_uri');
      const amazonState = searchParams.get('amazon_state');

      // Handle different URL parameter scenarios
      const handleQueryParams = async () => {
        // Scenario 1: Auth code present - handle authentication
        if (code) {
          await handleAuthCodeFlow(code);
          setFormLoading(false);
          return;
        }

        // Scenario 2: Callback URI present without code - handle redirect
        if (callbackUri && amazonState && !code) {
          handleCallbackRedirect(callbackUri, amazonState);
          return;
        }

        // Scenario 3: No special params - check auth status
        if (!code && !callbackUri && !amazonState && !checkingStatus.current) {
          await checkAuthStatus(true);
        }
      };

      handleQueryParams();
    }
    setFormLoading(false);

    return cleanup;
  }, [adminToken, searchParams]);

  // Render
  return (
    <FormRequestBlock
      styles={{ form: { flexDirection: 'column', gap: '10px' } }}
      title="Authorize Rye to access your Amazon Business Account"
      disabled={isReadOnly}
      text={
        amazonBusinessAccount?.oauthStatus === AmazonBusinessOauthStatus.Active
          ? 'In order to begin placing orders with Rye, you need to fill in the Amazon account details below.'
          : 'Connect Rye to your own Amazon Business Account'
      }
      loading={formLoading}
    >
      <div className="amazon-business-form">
        {amazonBusinessAccount?.oauthStatus !== AmazonBusinessOauthStatus.Active ? (
          <div className="amazon-business-body">
            <FormButton
              disabled={formLoading || isReadOnly}
              onClick={handleAuthentication}
              className="w-[120px]"
            >
              Connect
            </FormButton>
          </div>
        ) : (
          <div className="amazon-business-body">
            <div>
              <h3 className="form-request-block-title mb-[5px] flex-1">Amazon account email</h3>
              <FormInput
                id="amazon-business-input"
                className="amazon-business-input"
                type="text"
                disabled={isReadOnly}
                value={amazonAccountEmail}
                placeholder="Amazon Account Email"
                onChange={setAmazonAccountEmail}
              />
            </div>
            <div>
              <h3 className="form-request-block-title mb-[5px] flex-1">Group ID</h3>
              <FormInput
                id="amazon-business-account-email"
                className="amazon-business-input"
                type="text"
                disabled={isReadOnly}
                value={inputValue}
                placeholder="GroupId"
                onChange={setInputValue}
              />
            </div>
            <div>
              <h3 className="form-request-block-title mb-[5px] flex-1">Buyer email</h3>
              <FormInput
                id="amazon-business-input-buyer-email"
                className="amazon-business-input"
                type="text"
                value={amazonEmail}
                placeholder="BuyerEmail"
                disabled={isReadOnly}
                onChange={setAmazonEmail}
              />
            </div>
            <div className="flex flex-row justify-end gap-[10px]">
              <FormButton
                disabled={formLoading || isReadOnly}
                onClick={handleSave}
                className="w-[120px]"
              >
                Save
              </FormButton>
              <FormButton
                className={`amazon-business-oauth w-[120px]`}
                onClick={handleRevoke}
                disabled={isReadOnly || formLoading}
              >
                Disconnect
              </FormButton>
            </div>
          </div>
        )}
      </div>
    </FormRequestBlock>
  );
};

export default AmazonBusiness;
