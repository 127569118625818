import './styles.scss';
import type { AmazonOrderRequestProduct, AmazonProductPrice } from '@rye-com/rye-data-layer';
import { useQuery } from '@apollo/client';
import {
  getRequestAmazonProductInputById,
  REQUEST_AMAZON_PRODUCT_BY_ID,
} from 'app/graphql/cart-api';
import { graphQLClient } from 'app/graphql/client';
import { RequestAmazonProductByIdQuery } from 'app/graphql/generated/cart/graphql';
import { formatPrice } from 'utils/formatters';

const DEFAULT_QUANTITY = 1;

interface ItemCatalogCardValue {
  quantityMap: AmazonOrderRequestProduct[];
  productId: string;
  price?: AmazonProductPrice;
}

export const AmazonItemCatalogCard = (props: ItemCatalogCardValue) => {
  const amazonProduct = useQuery<RequestAmazonProductByIdQuery>(REQUEST_AMAZON_PRODUCT_BY_ID, {
    variables: getRequestAmazonProductInputById(String(props.productId)),
    skip: graphQLClient.authToken === null,
  });

  return !amazonProduct.loading && amazonProduct.data && amazonProduct.data.productByID ? (
    <tr>
      <td>
        {amazonProduct.data.productByID.images.length > 0 && (
          <img
            className="item-catalog-product-image"
            alt={amazonProduct.data.productByID.title}
            src={amazonProduct.data.productByID.images[0].url}
          />
        )}
      </td>
      <td className="item-catalog-product-description">
        <div className="item-catalog-product-description-row">
          <b>Name</b>
          <div>{amazonProduct.data.productByID.title}</div>
        </div>
        {amazonProduct.data.productByID.description && (
          <div className="item-catalog-product-description-row">
            <b>Description</b>
            <div>{amazonProduct.data.productByID.description}</div>
          </div>
        )}
        <div className="item-catalog-product-description-row">
          <b>Product Link</b>
          <div>
            <a href={amazonProduct.data.productByID.url} target="_blank" rel="noreferrer">
              {amazonProduct.data.productByID.url}
            </a>
          </div>
        </div>
      </td>
      <td>
        {props.quantityMap.find(
          (requestProduct) => requestProduct.productId === amazonProduct.data?.productByID?.id,
        )?.quantity ?? DEFAULT_QUANTITY}
      </td>
      <td>
        {props.price?.value
          ? formatPrice(props.price.value / 100, props.price.currencyCode)
          : amazonProduct.data.productByID.price?.displayValue ?? 'N/A'}
      </td>
    </tr>
  ) : null;
};
