import { useQuery } from '@apollo/client';
import { useRef } from 'react';

import { Loader2Icon } from 'lucide-react';

import { cn } from 'lib/utils';
import { MerchantIntegrationStatusType } from 'app/graphql/generated/admin/graphql';

import { MERCHANT_BY_DOMAIN } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';

export function MerchantIntegrationStatus({ canonicalDomain }: { canonicalDomain: string }) {
  const { adminToken } = useAppSelector(selectUserInfo) || {};

  const shouldPoll = useRef(true);

  const { data, loading } = useQuery(MERCHANT_BY_DOMAIN, {
    context: { clientName: 'admin' },
    fetchPolicy: 'no-cache',
    pollInterval: shouldPoll.current ? 1_000 : undefined,
    skip: !adminToken,
    variables: { domain: canonicalDomain },
  });
  const integrationStatus = data?.merchantByDomain?.integrationStatus;

  const isMerchantConnected =
    integrationStatus === MerchantIntegrationStatusType.Integrated ||
    integrationStatus === MerchantIntegrationStatusType.DirectlyIntegrated;
  const isDirectlyIntegrated =
    integrationStatus === MerchantIntegrationStatusType.DirectlyIntegrated;

  if (isDirectlyIntegrated) {
    // Stop polling once we reach terminal state
    shouldPoll.current = false;
  }

  return (
    <>
      <div
        className={cn(
          'flex items-center gap-1',
          isMerchantConnected ? 'text-green-500' : 'text-muted-foreground',
          { 'animate-pulse': loading && !isMerchantConnected },
        )}
      >
        {isMerchantConnected ? (
          'Rye connector installed.'
        ) : (
          <>
            Waiting for merchant to install Rye connector.{' '}
            {loading && (
              <span className="animate-pulse">
                <Loader2Icon className="size-2 animate-spin" />
              </span>
            )}
          </>
        )}
      </div>
      {isMerchantConnected && (
        <div
          className={cn(
            'flex items-center gap-1',
            isDirectlyIntegrated ? 'text-green-500' : 'text-muted-foreground',
            { 'animate-pulse': loading && !isDirectlyIntegrated },
          )}
        >
          {isDirectlyIntegrated ? (
            'Merchant is connected to you.'
          ) : (
            <>
              Checking merchant's connection to your account.{' '}
              {loading && (
                <span className="animate-pulse">
                  <Loader2Icon className="size-2 animate-spin" />
                </span>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
